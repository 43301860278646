<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron líneas de presupuesto"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idlpresupuestocli"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <lpresupuestocli-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :has-perm-borrar="presupuestoPendiente && hasDeletePerm(permissions.lpresupuestocli.id)"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="presupuestoPendiente && hasInsertPerm(permissions.lpresupuestocli.id)"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './LpresupuestocliListData'
import LpresupuestocliListItem from './components/LpresupuestocliListItem'
import { get } from 'vuex-pathify'
import { PRESUPUESTOCLI } from '@/utils/consts'

const pageStoreName = 'pagesLpresupuestocliList'

export default {
  components: {
    LpresupuestocliListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      presupuestocli: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    presupuestoPendiente () {
      return (
        this.presupuestocli &&
        PRESUPUESTOCLI.estadosPendientes.includes(this.presupuestocli.idest_presupuestocli)
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Artículos y mano de obra de presupuesto'
      const resp = await Data.selectLoadPage(this, this.routeParams.idpresupuestocli)
      this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectLpresupuestocli(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idpresupuestocli
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectLpresupuestocliRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idlpresupuestocli')
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'presupuestos__lineas__lpresupuestocli-view',
        params: {
          idpresupuestocli: item.data.idpresupuestocli,
          idlpresupuestocli: item.data.idlpresupuestocli,
        }
      })
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'presupuestos__lineas__lpresupuestocli-add',
        params: {
          idpresupuestocli: this.routeParams.idpresupuestocli,
        },
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar la línea de presupuesto "${this.$online.lpresupuestocli.title(data.item)}"?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await Data.deleteLpresupuestocli(this, data.item.idlpresupuestocli)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  }
}
</script>
