<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.es_mano_de_obra"
      >
        <v-chip
          class="mr-1"
          small
          label
          dark
          :color="ARTICULO.colores.manoDeObra"
        >
          Mano de obra
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <b10-list-item-right-avatar>
      {{ formattedItem.importe|currency }}
    </b10-list-item-right-avatar>
    <v-list-item-icon>
      <v-btn
        v-if="hasPermBorrar"
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { ARTICULO } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    hasPermBorrar: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      ARTICULO,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.lpresupuestocli.title(this.item)
      item.subtitle = this.$online.lpresupuestocli.subtitle(this.item)
      item.avatar = item.unidades
      item.avatarColor = colors.green.base
      return item
    },
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
