import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idpresupuestocli) {
    const apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLpresupuestocli (Vue, filter, search, sorter, page, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.lpresupuestocli.searchFilter(search))
    }
    if (filter.codigo.value) {
      apiFilter.addILike(filter.codigo.field, filter.codigo.value)
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    if (filter.infComercial.value) {
      apiFilter.addILike(filter.infComercial.field, filter.infComercial.value)
    }
    const resp = await Vue.$api.call('lpresupuestocli.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLpresupuestocliRows (Vue, pks) {
    const apiFilter = new APIFilter()
      .addIn('idlpresupuestocli', pks)
    const resp = await Vue.$api.call('lpresupuestocli.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteLpresupuestocli (Vue, idlpresupuestocli) {
    await Vue.$api.call('lpresupuestocli.delete', { idlpresupuestocli })
  },
}
